export const color = [
  "#00ffd5",
  "#009cff",
  "#009cff",
  "#026BD4",
  "#5584B3",
  "#33A647",
  "#52daf3",
  "#ff9f7f",
  "#fb7293",
  "#e7bcf3",
  "#8378ea",
  "#909399",
  "#faaf2f",
  "#efea9a",
  "#ffdb5c",
  "#5F7162",
  "#8BE09A",
  "#A1C5A7",
  "#E0DD39",
  "#F2EF7B",
  "#CD79C4",
  "#D9A7D4",
  "#B790B3",
  "#5B0B53",
  "#CCC1CA",
  "#524392",
];
export const hex2rgb = (a, value) => {
  if (a === "") {
    return "";
  }
  a = a.substring(1);
  a = a.toLowerCase();
  const b = new Array();
  for (let x = 0; x < 3; x++) {
    b[0] = a.substr(x * 2, 2);
    b[3] = "0123456789abcdef";
    b[1] = b[0].substr(0, 1);
    b[2] = b[0].substr(1, 1);
    b[20 + x] = b[3].indexOf(b[1]) * 16 + b[3].indexOf(b[2]);
  }
  return "rgba(" + b[20] + "," + b[21] + "," + b[22] + "," + value + ")";
};
export const scale = true;
