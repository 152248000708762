import * as echarts from "echarts";
import { color as COLOR, hex2rgb } from "@/unit/common-params";

function getDpr(num) {
  let width = window.screen.width;
  if (width > 1920) {
    return num;
  } else {
    return num / 2;
  }
}
// 空心饼图
export function getHollowPie(obj, datavalue) {
  console.log("sadadasda");
  let color = [
    { color1: "rgba(0, 156, 255, 1)", color2: "rgba(0, 156, 255, 0)" },
    { color1: "rgba(170, 85, 0,1)", color2: "rgba(170, 85, 0, 0)" },
    { color1: "rgba(255, 0, 255, 1)", color2: "rgba(255, 0, 255, 0)" },
    { color1: "rgba(255, 0, 0, 1)", color2: "rgba(255, 0, 0, 0)" },
    { color1: "rgba(254, 232, 107,1)", color2: "rgba(254, 232, 107, 0)" },
  ];
  let data = [];
  datavalue.forEach((item) => {
    let color1 = {};
    if (item.name === "一般") {
      color1 = color[1];
    } else if (item.name === "无报警") {
      color1 = color[0];
    } else if (item.name === "严重") {
      color1 = color[2];
    } else if (item.name === "致命") {
      color1 = color[3];
    } else if (item.name === "可疑") {
      color1 = color[4];
    }
    data.push({
      name: item.name,
      value: item.value,
      itemStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: color1.color1, // 0% 处的颜色
            },
            {
              offset: 1,
              color: color1.color2, // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    });
  });
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  let option = {
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    title: {
      show: true,
      text: "报警占比",
      left: "55%",
      top: "middle",
      textStyle: {
        color: "#fff",
        fontSize: getDpr(32),
      },
    },
    legend: {
      show: true,
      left: "8%",
      top: "middle",
      orient: "vertical",
      itemWidth: getDpr(30),
      itemHeight: getDpr(15),
      itemGap: getDpr(26),
      icon: "roundRect",
      textStyle: {
        color: "#fff",
        fontSize: getDpr(24),
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        center: ["63%", "50%"],
        radius: ["48%", "72%"],
        avoidLabelOverlap: false,
        itemStyle: {
          // borderRadius: 10,
          // borderColor: "#fff",
          // borderWidth: 5,
        },
        label: {
          show: true,
          fontSize: getDpr(24),
          padding: [0, -getDpr(120)],
          formatter: "{b}:{c}\n",
          color: "#fff",
          //position: "center",
        },
        labelLine: {
          show: true,
          length: getDpr(40),
          length2: getDpr(120),
        },
        emphasis: {
          label: {
            show: true,
            // fontSize: "40",
            fontWeight: "bold",
          },
        },
        data: data,
      },
    ],
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
  return myChart;
}
// 单条数据的饼图
export function getSingleDataPie(obj, num = 80, color = ["#fff", "#000"]) {
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  //   let angle = 0; //角度，用来做简单的动画效果的
  let option = {
    color: color,
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    title: {
      text: "{a|" + num + "}%",
      left: "center",
      top: "middle",
      textStyle: {
        color: color[0],
        fontSize: getDpr(50),
        fontFamily: "Oswald",
        rich: {
          a: {
            fontSize: getDpr(50),
            fontFamily: "Oswald",
          },
        },
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        center: ["50%", "50%"],
        radius: ["73%", "87%"],
        label: { show: false },
        emphasis: {
          scale: false,
        },
        data: [
          {
            name: "",
            value: num,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: color[0], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: color[1], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderRadius: "100%",
            },
          },
          {
            name: "",
            value: 100 - num,
            itemStyle: {
              color: "rgba(0,0,0,0)",
              // borderRadius: "100%",
            },
          },
        ],
      },
      // {
      //   type: "custom",
      //   renderItem: function (params, api) {
      //     console.log(params, "111");
      //   },
      //   data: [2],
      // },
      //   {
      //     name: "ring5", //绿点
      //     type: "custom",
      //     coordinateSystem: "none",
      //     renderItem: function (params, api) {
      //       let x0 = api.getWidth() / 2;
      //       let y0 = api.getHeight() / 2;
      //       let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
      //       let point = getCirlPoint(x0, y0, r, 180 + -angle);
      //       return {
      //         type: "circle",
      //         shape: {
      //           cx: point.x,
      //           cy: point.y,
      //           r: 4,
      //         },
      //         style: {
      //           stroke: "#0CD3DB", //绿
      //           fill: "#0CD3DB",
      //         },
      //         silent: true,
      //       };
      //     },
      //     data: [0],
      //   },
      //   {
      //     name: "ring5", //绿点
      //     type: "custom",
      //     coordinateSystem: "none",
      //     renderItem: function (params, api) {
      //       let x0 = api.getWidth() / 2;
      //       let y0 = api.getHeight() / 2;
      //       let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 1;
      //       let point = getCirlPoint(x0, y0, r, 0 + -angle);
      //       return {
      //         type: "circle",
      //         shape: {
      //           cx: point.x,
      //           cy: point.y,
      //           r: 4,
      //         },
      //         style: {
      //           stroke: "#0CD3DB", //绿
      //           fill: "#0CD3DB",
      //         },
      //         silent: true,
      //       };
      //     },
      //     data: [0],
      //   },
    ],
  };
  //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
  //   function getCirlPoint(x0, y0, r, angle) {
  //     let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
  //     let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
  //     return {
  //       x: x1,
  //       y: y1,
  //     };
  //   }
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
// 面积渐变线形图
export function getLine(obj, serice) {
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  let option = {
    title: {
      show: false,
      left: "2%",
      top: "4%",
      text: "",
      textStyle: {
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    grid: {
      top: "16%",
      left: "2%",
      bottom: "2%",
      right: "4%",
      containLabel: true,
    },
    legend: {
      show: true,
      right: "4%",
      top: "1%",
      type: "scroll",
      pageIconColor: "#12F1FE",
      pageIconInactiveColor: "#fff",
      pageTextStyle: {
        color: "#fff",
      },
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
      icon: "circle",
      itemWidth: 10,
      itemHeight: 10,
      // itemStyle: {
      //   shadowColor: "rgba(255, 255, 255, 0.6)",
      //   shadowBlur: 4,
      // },
    },
    xAxis: {
      type: "category",
      data: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      axisTick: { show: false },
      axisLabel: {
        fontSize: getDpr(22),
        color: "#fff",
        interval: 0,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: getDpr(22),
        color: "rgba(0, 156, 249, 1)",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0, 156, 249, 0.3)",
          type: "dashed",
        },
      },
    },
    series: serice,
    /*  series: [
      {
        name: "老厂房",
        type: "line",
        data: [
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
        ],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          color: "rgba(0, 156, 255, 1)",
          width: getDpr(2),
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 156, 255, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 156, 255, 0.5)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
      {
        name: "新厂房",
        type: "line",
        data: [
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
        ],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          color: "rgba(0, 255, 213, 1)",
          width: getDpr(2),
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 255, 213, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 255, 213, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 255, 213, 0.5)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 255, 213, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
      {
        name: "长沙先导",
        type: "line",
        data: [
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
        ],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          color: "rgba(254, 232, 107, 1)",
          width: getDpr(2),
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(254, 232, 107, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(254, 232, 107, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(254, 232, 107, 0.5)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(254, 232, 107, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
    ],*/
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
  return { myChart: myChart, option: option };
}
export const line2Option = {
  title: {
    show: false,
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(0,0,0,.75)",
    borderWidth: 0,
    textStyle: {
      color: "#fff",
      fontSize: getDpr(22),
    },
    extraCssText: "z-index:2",
  },
  grid: {
    top: "16%",
    left: "2%",
    bottom: "2%",
    right: "4%",
    containLabel: true,
  },
  legend: {
    show: true,
    right: "4%",
    top: "5%",
    textStyle: {
      color: "#fff",
      fontSize: getDpr(22),
    },
    icon: "circle",
    itemWidth: 10,
    itemHeight: 10,
    // itemStyle: {
    //   shadowColor: "rgba(255, 255, 255, 0.6)",
    //   shadowBlur: 4,
    // },
  },
  xAxis: {
    type: "time",
    data: [
      "00:00",
      "02:00",
      "04:00",
      "06:00",
      "08:00",
      "10:00",
      "12:00",
      "14:00",
      "16:00",
      "18:00",
      "20:00",
      "22:00",
      "24:00",
    ],
    axisTick: { show: true },
    axisLabel: {
      fontSize: getDpr(22),
      color: "#fff",
      interval: 0,
    },
    boundaryGap: false,
    axisLine: {
      lineStyle: {
        color: "rgba(0, 255, 213, 0.6)",
      },
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      fontSize: getDpr(22),
      color: "rgba(0, 156, 249, 1)",
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "rgba(0, 255, 213, 0.6)",
      },
    },
    splitLine: {
      lineStyle: {
        color: "rgba(0, 156, 249, 0.3)",
        type: "dashed",
      },
    },
  },
  series: [
    {
      name: "老厂房",
      type: "line",
      data: [],
      showSymbol: false,
      smooth: true,
      lineStyle: {
        color: "rgba(0, 156, 255, 1)",
        width: getDpr(2),
      },
      itemStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(0, 156, 255, 1)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(0, 156, 255, 0.5)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      markPoint: {
        symbol: "pin", //标记(气泡)的图形
        symbolSize: getDpr(70), //标记(气泡)的大小
        itemStyle: {
          color: "rgba(0, 156, 255, 0.5)", //图形的颜色。
          borderColor: "#2f54eb", //图形的描边颜色。
          borderWidth: 0, //描边线宽。
          borderType: "solid", //描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'等
        },
        data: [
          { type: "max", name: "最大值" },
          { type: "min", name: "最小值" },
        ],
      },
    },
  ],
};
// 面积渐变线形图
export function getLine2(obj) {
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  let option = {
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    grid: {
      top: "16%",
      left: "2%",
      bottom: "2%",
      right: "4%",
      containLabel: true,
    },
    legend: {
      show: true,
      right: "4%",
      top: "5%",
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
      icon: "circle",
      itemWidth: 10,
      itemHeight: 10,
      // itemStyle: {
      //   shadowColor: "rgba(255, 255, 255, 0.6)",
      //   shadowBlur: 4,
      // },
    },
    xAxis: {
      type: "category",
      data: [
        "02:00",
        "04:00",
        "06:00",
        "08:00",
        "10:00",
        "12:00",
        "14:00",
        "16:00",
        "18:00",
        "20:00",
        "22:00",
        "24:00",
      ],
      axisTick: { show: false },
      axisLabel: {
        fontSize: getDpr(22),
        color: "#fff",
        interval: 0,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: getDpr(22),
        color: "rgba(0, 156, 249, 1)",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0, 156, 249, 0.3)",
          type: "dashed",
        },
      },
    },
    series: [
      {
        name: "老厂房",
        type: "line",
        data: [
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
          parseInt(Math.random() * 1000),
        ],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          color: "rgba(0, 156, 255, 1)",
          width: getDpr(2),
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 156, 255, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 156, 255, 0.5)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        markPoint: {
          symbol: "pin", //标记(气泡)的图形
          symbolSize: getDpr(70), //标记(气泡)的大小
          itemStyle: {
            color: "rgba(0, 156, 255, 0.5)", //图形的颜色。
            borderColor: "#2f54eb", //图形的描边颜色。
            borderWidth: 0, //描边线宽。
            borderType: "solid", //描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'等
          },
          data: [
            { type: "max", name: "最大值" },
            { type: "min", name: "最小值" },
          ],
        },
      },
    ],
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
  //   循环出现提示框
  var currentIndex = -1;
  setInterval(function () {
    var dataLen = option.series[0].data.length;
    currentIndex = (currentIndex + 1) % dataLen;
    // console.log(currentIndex);

    myChart.dispatchAction({
      type: "showTip",
      seriesIndex: 0,
      dataIndex: currentIndex,
    });
  }, 2000);
}
// 面积渐变线形图
export function getLine4(obj, data) {
  let color = [
    {
      color1: "rgba(0, 255, 213, 1)",
      color2: "rgba(0, 255, 213, 0.5)",
      color3: "rgba(0, 255, 213, 0)",
    },
    {
      color1: "rgba(155, 99, 248, 1)",
      color2: "rgba(155, 99, 248, 0.5)",
      color3: "rgba(155, 99, 248, 0)",
    },
    {
      color1: "rgba(244, 50, 117, 1)",
      color2: "rgba(244, 50, 117, 0.5)",
      color3: "rgba(244, 50, 117, 0)",
    },
    {
      color1: "rgba(247, 226, 106, 1)",
      color2: "rgba(247, 226, 106, 0.5)",
      color3: "rgba(247, 226, 106, 0)",
    },
    {
      color1: "rgba(255, 120, 0, 1)",
      color2: "rgba(255, 120, 0, 0.5)",
      color3: "rgba(255, 120, 0, 0)",
    },
    {
      color1: "rgba(124, 107, 255, 1)",
      color2: "rgba(124, 107, 255, 0.5)",
      color3: "rgba(124, 107, 255, 0)",
    },
    {
      color1: "rgba(76, 187, 255, 1)",
      color2: "rgba(76, 187, 255, 0.5)",
      color3: "rgba(76, 187, 255, 0)",
    },
  ];
  COLOR.map((item) => {
    color.push({
      color1: hex2rgb(item, 1),
      color2: hex2rgb(item, 0.5),
      color3: hex2rgb(item, 0),
    });
  });
  console.log("color", color);
  let yxis = [];

  yxis.push({
    type: "value",
    name: "kWh",
    nameLocation: "end",
    axisLabel: {
      fontSize: getDpr(22),
      color: "rgba(0, 156, 249, 1)",
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "rgba(0, 255, 213, 0.6)",
      },
    },
    splitLine: {
      lineStyle: {
        color: "rgba(0, 156, 249, 0.3)",
        type: "dashed",
      },
    },
  });
  let series = [];
  data.data.forEach((item, index) => {
    series.push({
      name: item.name,
      type: "line",
      data: item.data,
      showSymbol: false,
      smooth: true,
      lineStyle: {
        color: color[index].color1,
        width: getDpr(2),
      },
      itemStyle: {
        color: color[index].color1,
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: color[index].color2, // 0% 处的颜色
            },
            {
              offset: 1,
              color: color[index].color3, // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    });
  });
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  myChart.clear();
  // 绘制图表
  let option = {
    title: {
      show: false,
      left: "2%",
      top: "4%",
      text: "两慢病人数分析",
      textStyle: {
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,

      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    grid: {
      top: "20%",
      left: "2%",
      bottom: "2%",
      right: "4%",
      containLabel: true,
    },
    legend: {
      show: true,
      left: "10%",
      top: "2%",
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
      type: "scroll",
      pageIconColor: "#12F1FE",
      pageIconInactiveColor: "#fff",
      pageTextStyle: {
        color: "#fff",
      },
      icon: "circle",
      itemWidth: 10,
      itemHeight: 10,
      // itemStyle: {
      //   shadowColor: "rgba(255, 255, 255, 0.6)",
      //   shadowBlur: 4,
      // },
    },
    xAxis: {
      type: "category",
      data: data.time,
      axisTick: { show: false },
      axisLabel: {
        fontSize: getDpr(22),
        color: "#fff",
        interval: 0,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
    },
    yAxis: yxis,
    series: series,
  };
  console.log("option", option);
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
// 面积渐变线形图
export function getLine3(obj, data) {
  let color = [
    {
      color1: "rgba(0, 255, 213, 1)",
      color2: "rgba(0, 255, 213, 0.5)",
      color3: "rgba(0, 255, 213, 0)",
    },
    {
      color1: "rgba(155, 99, 248, 1)",
      color2: "rgba(155, 99, 248, 0.5)",
      color3: "rgba(155, 99, 248, 0)",
    },
    {
      color1: "rgba(244, 50, 117, 1)",
      color2: "rgba(244, 50, 117, 0.5)",
      color3: "rgba(244, 50, 117, 0)",
    },
    {
      color1: "rgba(247, 226, 106, 1)",
      color2: "rgba(247, 226, 106, 0.5)",
      color3: "rgba(247, 226, 106, 0)",
    },
    {
      color1: "rgba(255, 120, 0, 1)",
      color2: "rgba(255, 120, 0, 0.5)",
      color3: "rgba(255, 120, 0, 0)",
    },
    {
      color1: "rgba(124, 107, 255, 1)",
      color2: "rgba(124, 107, 255, 0.5)",
      color3: "rgba(124, 107, 255, 0)",
    },
    {
      color1: "rgba(76, 187, 255, 1)",
      color2: "rgba(76, 187, 255, 0.5)",
      color3: "rgba(76, 187, 255, 0)",
    },
  ];
  COLOR.map((item) => {
    color.push({
      color1: hex2rgb(item, 1),
      color2: hex2rgb(item, 0.5),
      color3: hex2rgb(item, 0),
    });
  });
  console.log("color", color);
  let yxis = [];
  data.unit.forEach((item, index) => {
    yxis.push({
      type: "value",
      name: item,
      nameLocation: "end",
      axisLabel: {
        fontSize: getDpr(22),
        color: "rgba(0, 156, 249, 1)",
      },
      position: index % 2 == 0 ? "left" : "right",
      offset: parseInt(index / 2) * 20,
      axisLine: {
        show: index > 1 ? false : true,
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0, 156, 249, 0.3)",
          type: "dashed",
        },
      },
    });
  });
  let series = [];
  data.data.forEach((item, index) => {
    series.push({
      name: item.name,
      type: "line",
      data: item.data,
      showSymbol: false,

      yAxisIndex: item.yAxisIndex,
      smooth: true,
      lineStyle: {
        color: color[index].color1,
        width: getDpr(2),
      },
      itemStyle: {
        color: color[index].color1,
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: color[index].color2, // 0% 处的颜色
            },
            {
              offset: 1,
              color: color[index].color3, // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    });
  });
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  myChart.clear();
  // 绘制图表
  let option = {
    title: {
      show: false,
      left: "2%",
      top: "4%",
      text: "两慢病人数分析",
      textStyle: {
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      formatter: function (params) {
        var relVal = params[0].data[0];
        for (var i = 0, l = params.length; i < l; i++) {
          relVal +=
            "<br/>" +
            params[i].marker +
            params[i].seriesName +
            " : " +
            params[i].data[1] +
            params[i].data[2];
        }
        return relVal;
      },
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    grid: {
      top: "20%",
      left: "2%",
      bottom: "2%",
      right: "4%",
      containLabel: true,
    },
    legend: {
      show: true,
      left: "10%",
      top: "2%",
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
      type: "scroll",
      pageIconColor: "#12F1FE",
      pageIconInactiveColor: "#fff",
      pageTextStyle: {
        color: "#fff",
      },
      icon: "circle",
      itemWidth: 10,
      itemHeight: 10,
      // itemStyle: {
      //   shadowColor: "rgba(255, 255, 255, 0.6)",
      //   shadowBlur: 4,
      // },
    },
    xAxis: {
      type: "time",
      data: data.xdata,
      axisTick: { show: false },
      axisLabel: {
        fontSize: getDpr(22),
        color: "#fff",
        interval: 0,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "rgba(0, 255, 213, 0.6)",
        },
      },
    },
    yAxis: yxis,
    series: series,
  };
  console.log("option", option);
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
// 雷达图
export function getRadar(obj, data) {
  let data1 = data;
  console.log("data1", data1);

  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  let option = {
    tooltip: {
      show: true,
      alwaysShowContent: true,
      position: ["68%", "20%"],
      backgroundColor: "rgba(0,0,0,0)",
      padding: 0,
      borderWidth: 0,
      // triggerOn: "none",
      formatter: function (params) {
        let html = `<div class='tooltip u-flex u-flex-col u-col-top'>
       <h3>${params.name}</h3>
        <span><i class="dot" style="background:${params.color.colorStops[0].color}"></i>检修：<i class="num">${params.data.value[0]}</i></span>
        <span><i class="dot" style="background:${params.color.colorStops[0].color}"></i>维护：<i class="num">${params.data.value[1]}</i></span>
        <span><i class="dot" style="background:${params.color.colorStops[0].color}"></i>保养：<i class="num">${params.data.value[2]}</i></span>
        <span><i class="dot" style="background:${params.color.colorStops[0].color}"></i>巡检：<i class="num">${params.data.value[3]}</i></span>
        <span><i class="dot" style="background:${params.color.colorStops[0].color}"></i>其他：<i class="num">${params.data.value[4]}</i></span>
        </div>`;
        return html;
      },
    },
    legend: {
      show: true,
      orient: "vertical",
      left: "5%",
      top: "middle",
      icon: "roundRect",
      itemWidth: getDpr(30),
      itemHeight: getDpr(16),
      // selectedMode: "single",
      data: ["历史任务统计"],
      textStyle: {
        fontSize: getDpr(24),
        color: "#fff",
      },
    },
    radar: {
      // shape: 'circle',
      radius: "70%",
      center: ["46%", "50%"],
      shape: "circle",
      axisLine: {
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 79, 128, 1)",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0, 79, 128, 1)",
        },
      },
      splitArea: {
        show: false,
      },
      indicator: [
        { name: "检修", max: 500 },
        { name: "维护", max: 500 },
        { name: "保养", max: 500 },
        { name: "巡检", max: 500 },
        { name: "其他", max: 500 },
      ],
      axisName: {
        color: "#fff",
        fontSize: getDpr(22),
      },
      nameGap: getDpr(14),
    },
    series: [
      {
        name: "历史任务统计",
        type: "radar",
        lineStyle: {
          color: "rgba(0, 156, 255, 1)",
        },
        areaStyle: {
          color: "rgba(0, 156, 255, 0.25)",
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 156, 255, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 156, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          // shadowColor: "rgba(0, 156, 255, 0.6)",
          // shadowBlur: 4,
        },
        data: data1,
      },
    ],
  };

  myChart.setOption(option);
  myChart.on("finished", function () {
    myChart.dispatchAction({
      type: "showTip",
      seriesIndex: 0,
      dataIndex: 0,
    });
  });
  //   setInterval(() => {
  //     data = [
  //       {
  //         value: [
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //         ],
  //         name: "",
  //       },
  //       {
  //         value: [
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //           Math.random() * 50 + 50,
  //         ],
  //         name: "",
  //       },
  //       {
  //         value: [
  //           Math.random() * 50 + 20,
  //           Math.random() * 50 + 20,
  //           Math.random() * 50 + 20,
  //           Math.random() * 50 + 20,
  //           Math.random() * 50 + 20,
  //           Math.random() * 50 + 20,
  //         ],
  //         name: "",
  //         symbol: "none",
  //       },
  //       {
  //         value: [
  //           Math.random() * 50 + 10,
  //           Math.random() * 50 + 10,
  //           Math.random() * 50 + 10,
  //           Math.random() * 50 + 10,
  //           Math.random() * 50 + 10,
  //           Math.random() * 50 + 10,
  //         ],
  //         name: "",
  //         symbol: "none",
  //       },
  //       {
  //         value: [
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //         ],
  //         name: "",
  //         symbol: "none",
  //       },
  //       {
  //         value: [
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //           Math.random() * 30 + 10,
  //         ],
  //         name: "",
  //         symbol: "none",
  //       },
  //     ];
  //     option = {
  //       series: [{ data: data }],
  //     };
  //     myChart.setOption(option);
  //   }, 3000);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
// 柱状图
export function getSeriesSingleColorBar(obj, name, odata) {
  let data = [];
  odata.forEach((item) => {
    data.push({
      value: item,
    });
  });
  //   基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById(obj));
  // 绘制图表
  let option = {
    title: {
      show: false,
      left: "2%",
      top: "4%",
      text: "",
      textStyle: {
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      backgroundColor: "rgba(0,0,0,.75)",
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: getDpr(22),
      },
    },
    grid: [
      {
        top: "10%",
        left: "10%",
        bottom: "10%",
        right: "2%",
        containLabel: false,
      },
    ],
    legend: {
      show: false,
      right: "4%",
      top: "5%",
      textStyle: {
        color: "#fff",
      },
      icon: "roundRect",
      itemWidth: 20,
      itemHeight: 10,
    },
    xAxis: [
      {
        type: "category",
        show: true,
        data: name,
        axisTick: { show: false },
        axisLabel: {
          fontSize: getDpr(22),
          color: "#fff",
          interval: 0,
          show: false,
          formatter: function (params, index) {
            if (index % 2 != 0) {
              return "\n\n" + params;
            } else {
              return params;
            }
          },
        },
        boundaryGap: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(0, 156, 249, 0.4)",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        show: true,
        axisLabel: {
          fontSize: getDpr(22),
          color: "rgba(0, 156, 249, 1)",
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(0, 156, 249, 0.4)",
          },
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "rgba(0, 156, 249, 0.3)",
          },
        },
      },
    ],
    series: [
      {
        name: "",
        type: "bar",
        data: data,
        barWidth: "20%",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(255, 255, 255, 0.07)",
          borderColor: "rgba(0,121,120,1)",
          borderWidth: 0,
          borderRadius: 0,
          shadowColor: "rgba(0,121,120,0)",
          shadowBlur: 0,
        },
        label: {
          show: false,
          position: "top",
          textStyle: {
            color: "#fff",
            fontSize: 20,
            fontWeight: 700,
            fontFamily: "Oswald",
          },
        },
        itemStyle: {
          // barBorderRadius: 0,
          // borderColor: "#12A3FF",
          borderWidth: 1,
          barBorderRadius: 15,
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0, 155, 255, 1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(0, 155, 255, 0)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
    ],
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
  return { myChart: myChart, option: option };
}
