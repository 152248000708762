import request from "@/unit/request";
//站点告警
export function getServiceTransformerList(params) {
  return request({
    url: "/api/serviceTransformer/list",
    method: "get",
    params,
  });
}
export function getBigDataSingle(params) {
  return request({
    url: "/api/v2/getBigDataSingle",
    method: "get",
    params,
  });
}
export function getPowerListDetail(params) {
  return request({
    url: "/api/getPowerListDetail",
    method: "get",
    params,
  });
}
export function getBigDataLoadV1(params) {
  return request({
    url: "/api/v2/getBigDataLoadV1",
    method: "get",
    params,
  });
}

export function getInspections(params) {
  return request({
    url: "/api/v2/getInspections",
    method: "get",
    params,
  });
}
export function getVisions(params) {
  return request({
    url: "/api/v2/getVisions",
    method: "get",
    params,
  });
}
export function getVisionData(params) {
  return request({
    url: "/api/v2/getVisionData",
    method: "get",
    params,
  });
}
export function getPcVideos(params) {
  return request({
    url: "/api/v2/getPcVideos",
    method: "get",
    params,
  });
}
export function getEquipmentClass(params) {
  return request({
    url: "/api/v2/getEquipmentClass/list",
    method: "get",
    params,
  });
}

export function getEquipmentList(params) {
  return request({
    url: "/api/v2/getEquipment/list",
    method: "get",
    params,
  });
}
export function getEquipmentDetail(params) {
  return request({
    url: "/api/v2/getEquipment/detail",
    method: "get",
    params,
  });
}
export function getEquipmentEquipDay(params) {
  return request({
    url: "/api/v2/getEquipment/equipDay",
    method: "get",
    params,
  });
}
export function getEquipmentEquipWeek(params) {
  return request({
    url: "/api/v2/getEquipment/equipWeek",
    method: "get",
    params,
  });
}
export function getEquipmentEquipMonth(params) {
  return request({
    url: "/api/v2/getEquipment/equipMonth",
    method: "get",
    params,
  });
}
export function getEquipParameter(params) {
  return request({
    url: "/api/v2/getEquipParameter",
    method: "get",
    params,
  });
}
export function getEquipmentEquipYear(params) {
  return request({
    url: "/api/v2/getEquipment/equipYear",
    method: "get",
    params,
  });
}
export function getChartsTemplate(params) {
  return request({
    url: "/api/v2/getChartsTemplate",
    method: "get",
    params,
  });
}

export function setChartsTemplate(params) {
  return request({
    url: "/api/v2/setChartsTemplate",
    method: "post",
    params,
  });
}
